<template>
  <div class="change-shop">
    <div class="content">
      <logo class="logo" />
      <div class="title">Let's connect your store!</div>
      <div class="list">
        <img src="../../assets/img/shopify.svg">
        <div class="center">
          <p>Shopify</p>
          <p>Don't have a Shopify account?<a @click="goShopify">Sign Up</a></p>
        </div>
        <a-button type="primary" @click="handConnect(1)">
          Connect
        </a-button>
      </div>
      <div class="list">
        <img src="../../assets/img/woocommerce.svg">
        <div class="center">
          <p>WooCommerce</p>
          <p>
            WooCommerce needs to be running on a self-hosted WordPress site.
          </p>
        </div>
        <a-button type="primary" @click="handConnect(2)">
          Connect
        </a-button>
      </div>
      <div class="list">
        <img src="../../assets/img/etsy.png">
        <div class="center">
          <p>Etsy</p>
          <p>
            The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
          </p>
        </div>
        <a-button type="primary" @click="toConnectEtsy">
          Connect
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getEtsyConnectUrl } from '@/api/etsy'
export default {
  data() {
    return {
      shopId: ''
    }
  },
  mounted() {
    if (this.$route.query.shopId) {
      this.shopId = this.$route.query.shopId
    }
  },
  methods: {
    handConnect(val) {
      if (this.shopId) {
        this.callNewPage({
          path: '/connentShop',
          query: { store: val, shopId: this.shopId }
        })
      } else {
        this.callNewPage({ path: '/connentShop', query: { store: val }})
      }
    },
    goShopify() {
      window.open('https://www.shopify.com')
    },
    toConnectEtsy() {
      var postData = {}
      postData.shopId = this.shopId
        if(location.hostname === 'dashboard.jetprintapp.com'){
            postData.callbackUrl = 'https://dashboard.jetprintapp.com/etsy/installs'
        }
        if(location.hostname === 'jetprintapp.com'){
            postData.callbackUrl = 'https://jetprintapp.com/app/etsy/installs'
        }
        if(location.hostname === 'www.jetprintapp.com'){
            postData.callbackUrl = 'https://www.jetprintapp.com/app/etsy/installs'
        }
      getEtsyConnectUrl(postData).then(response => {
        window.open(response.data)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.change-shop {
  height: 100vh;
  background: #f2f2f2;
  margin-top: -62px;
}
.content {
  width: 572px;
  margin: 0 auto;
  text-align: center;
  padding-top: 62px;
  .logo {
    width: 132px;
    height: 38px;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.6px;
    color: #1b1b1b;
    margin: 20px 0 32px 0;
  }
  .list {
    width: 100%;
    padding: 24px;
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 16px;
    box-shadow: 0px 1px 3px rgba(148, 148, 148, 0.25),
      0px 2px 3px rgba(148, 148, 148, 0.15),
      0px 0px 2px rgba(148, 148, 148, 0.1);
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      width: 32px;
      margin-right: 24px;
      background-color: #f7f7f7;
    }
    p {
      text-align: left;
    }
    p:nth-child(1) {
      font-weight: bold;
      font-size: 19px;
      line-height: 22px;
      color: #1b1b1b;
      margin-bottom: 8px;
    }
    p:nth-child(2) {
      font-size: 13px;
    }
    button {
      margin-left: auto;
    }
  }
}
</style>
